import React, {
   useState,
   useEffect,
   useRef,
   ChangeEvent,
   memo,
   useMemo,
} from 'react'
import * as yup from 'yup'
import { Col, Row } from 'react-grid-system'
import { AddAuditView, AuditStatus } from './enums'
import { TableWrapper } from '../../common/table/TableWrapper'
import {
   CsvDownloadForm,
   PageHeader,
   Search,
   Modal,
   Button,
   LoadingOverlay,
   SnackBar,
   SlidePanel,
   Filters,
   ItemSelector,
   ItemAuditedList,
   CrudActionButtons,
   Accordion,
   Svg,
} from '../../common'
import { DateInput, Dropdown, Select } from '../../common/form/fields'
import FilterButton from '../../common/filters/filterButton/filterButton'
import FilterTags from '../../common/filters/filterTags/filterTags'
import { Actions, PAGINATION } from '../../../constants/tables'
import { Severity, Item as ItemType, Item } from '../../../types'
import { AUDIT_TABLE } from './constants'
import {
   DateRangeFilter,
   DropdownFilter,
} from '../../common/filters/filterTypes'
import {
   isEmptyObject,
   sortObjectsByAlpha,
} from '../../../common/utils/functions'
import {
   convertCategoriesToOptions,
   convertConditionsToOptions,
   convertConnectionsToOptions,
   convertItemStatusesToOptions,
   convertLocationToOptions,
   convertManufacturersToOptions,
   convertItemStatusStatesToOptions,
   convertItemTypesToOptions,
} from '../../common/filters/helpers'
import {
   useCrudItems,
   useFilters,
   useAudit,
   useAuditStatus,
   useAuditors,
   useConnections,
   useManufacturers,
   useCategories,
   useActiveLocations,
   useConditions,
   useSettings,
   useItemTypes,
} from '../../../hooks'
import { prepareAuditForAPI } from './helpers'
import { downloadCsv } from './helpers'
import type { Audit, AuditRequestType } from './types'

import * as auditsStyles from './audits.module.scss'
import {
   Filters as FiltersObject,
   FiltersForAPI,
} from '../../../hooks/useFilters/useFilters.type'
import { Option } from '../../common/form/fields/dropdown/dropdown.type'
import { format } from 'date-fns'
import { FORMATS } from '../../../constants/datetime'
import { flattenFilterObj } from '../../../hooks/useFilters/useFilters.helpers'
import { WarningModal } from '../../reusableModals/warningModal'
import {
   AuditItem,
   AuditRecordDetails,
} from '../../../hooks/useAudit/useAudit.type'
import { SnackBarState } from '../../common/snackBar/types'
import { closedSnackbarState } from '../../common/snackBar/snackBar'

const {
   extraClassName,
   modalTitle,
   placeholder,
   addButtonText,
   key,
   crudPaths,
   tableTitle,
   downloadRequest,
   downloadCsvText,
   exportFileName,
   exportItemsFileName,
   downloadPath,
   downloadHistoryPath,
   columnHeaders,
   radioToolStatus,
} = AUDIT_TABLE

const { query, skip, take } = PAGINATION

const filterSchema = yup.object().shape({})

const defaultAudit: Audit = {
   AuditID: null,
   DateSent: null,
   DateReceived: null,
   DateReviewed: null,
   DateDue: null,
   AuditedByID: '',
   Status: null,
   str_Status: null,
   AuditItems: [],
}

const defaultAuditRecordDetails: AuditRecordDetails = {
   AuditID: null,
   AuditItems: null,
   AuditedBy: '',
   AuditedByID: '',
   CreatedBy: null,
   DateCreated: null,
   DateDue: null,
   DateReceived: null,
   DateReviewed: null,
   DateSent: null,
   IdsForAddFinal: null,
   IdsForSelection: null,
   IsDeleted: null,
   IsDraftAuditRecord: null,
   ItemTotal: null,
   ItemsConfirmed: null,
   ItemsUnConfirmed: null,
   ItemsNotFoundInAudit: null,
   Status: null,
   Title: null,
   hidDateDue: null,
   str_Status: null,
}

function formatFiltersForAPI(filters: FiltersObject): FiltersForAPI {
   function formatDate(date: Date) {
      return format(date, FORMATS.filterDateAPI)
   }

   const startDateFilter = {
      ...(filters?.startDate?.value instanceof Date && {
         startDate: formatDate(filters?.startDate?.value),
      }),
   }
   const endDateFilter = {
      ...(filters?.endDate?.value instanceof Date && {
         endDate: formatDate(filters?.endDate?.value),
      }),
   }

   const status = Array.isArray(filters?.status?.value)
      ? filters?.status.value[0].value
      : null

   return {
      ...flattenFilterObj(filters),
      ...startDateFilter,
      ...endDateFilter,
      status,
   }
}

const Audits = () => {
   const { settings } = useSettings()
   const {
      data: auditData,
      get,
      refresh,
      remove,
      totalItems,
      setPath,
      isLoading,
      isRefetching,
      isCreating,
      isSuccessCreate,
      isErrorCreate,
      isUpdating,
      isSuccessUpdate,
      isErrorUpdate,
      isRemoving,
      isSuccessRemove,
      isErrorRemove,
   } = useCrudItems({ query, skip, take, apiMethod: 'post' })
   const {
      errors,
      modify,
      reset,
      remove: removeFilter,
      save,
      savedFilters,
      tmpFilters,
   } = useFilters(filterSchema, {})
   const {
      modify: modifyItemFilters,
      reset: resetItemFilters,
      save: saveItemFilters,
      savedFilters: savedItemFilters,
      tmpFilters: tmpItemFilters,
   } = useFilters(filterSchema, {})
   const {
      create: createAudit,
      isLoading: auditIsLoading,
      isRefetchingAuditRecord: auditIsRefetching,
      isSuccess: createIsSuccess,
      auditRecord,
      getAuditRecord,
      isUpdatingAuditDetails,
      isUpdatingDraftDetails,
      auditUnconfirmedItemsList,
      auditConfirmedItemsList,
      auditFoundItemsList,
      draftAuditItemsListAndCount,
      isPaginatingItems,
      markSelectedAuditAsReviewed,
   } = useAudit()
   const {
      auditorAvailabilityStatus,
      list: auditors,
      validateAuditorAvailability,
   } = useAuditors()
   // filter data
   const { list: auditStatuses } = useAuditStatus()
   const { list: connections } = useConnections()
   const { list: manufacturers } = useManufacturers()
   const { list: categories } = useCategories()
   const { list: locations } = useActiveLocations()
   const { list: conditions } = useConditions()
   const { list: itemTypes } = useItemTypes()

   const [selectedAudit, setSelectedAudit] = useState<Audit>(defaultAudit)
   const [selectedAuditFormData, setSelectedAuditFormData] =
      useState<AuditRecordDetails>(defaultAuditRecordDetails)
   const [initialSelectedAuditFormData, setInitialAuditFormData] =
      useState<AuditRecordDetails>(defaultAuditRecordDetails) // used to compare any unsaved changes on exit
   const [
      existingDraftAuditItemsForDeletion,
      setExistingDraftAuditItemsForDeletion,
   ] = useState<number[]>([])
   const [
      selectedAuditFormItemsUnconfirmed,
      setSelectedAuditFormItemsUnconfirmed,
   ] = useState<AuditItem[]>([])
   const [selectedAuditFormItemsConfirmed, setSelectedAuditFormItemsConfirmed] =
      useState<AuditItem[]>([])
   const [selectedAuditFormItemsFound, setSelectedAuditFormItemsFound] =
      useState<AuditItem[]>([])
   const [selectedAuditId, setSelectedAuditId] = useState<Number | null>(null)
   const [selectedItemsForAudit, setSelectedItemsForAudit] = useState<any>([])
   const [isAllItemsSelected, setIsAllItemsSelected] = useState<boolean>(false)
   const [finalisedSelectedItems, setFinalisedSelectedItems] = useState<any>([])
   const [searchMode, setSearchMode] = useState<boolean>(false)
   const [pageSized, setPageSized] = useState<number>(PAGINATION.pageSized)
   const [isPageReset] = useState<boolean>(false)
   const [isAddEditModalVisible, setIsAddEditModalVisible] =
      useState<boolean>(false)
   const [isDeleteModalVisible, setIsDeleteModalVisible] =
      useState<boolean>(false)
   const [isExportModalVisible, setIsExportModalVisible] =
      useState<boolean>(false)
   const [isDownloadingFileDirect, setIsDownloadingFileDirect] =
      useState<boolean>(false)
   const [searchCriteria, setSearchCriteria] = useState<string>('')
   const [snackbarState, setSnackbarState] =
      useState<SnackBarState>(closedSnackbarState)
   const [filtersOpen, setFiltersOpen] = useState<boolean>(false)
   const [isDownloading, setIsDownloading] = useState<boolean>(false)
   const [filterValues, setFilterValues] = useState({}) // filter values relate to audit filters
   const [itemFilterValues, setItemFilterValues] = useState({})
   const [addAuditView, setAddAuditView] = useState(AddAuditView.Default)
   const [isSelectAllItemsWarningVisible, setIsSelectAllItemsWarningVisible] =
      useState<boolean>(false)
   const [isCancelWarningVisible, setIsCancelWarningVisible] =
      useState<boolean>(false)
   const [auditIdForDeletion, setAuditIdForDeletion] = useState<number | null>(
      null
   )
   const [itemsWereAddedOrRemoved, setItemsWereAddedOrRemoved] =
      useState<boolean>(false)

   const formRef = useRef<HTMLFormElement>()
   const itemFilterFormRef = useRef<HTMLFormElement>()
   const buttonRef = useRef<HTMLInputElement | null>(null)

   useEffect(() => {
      if (selectedAuditId) {
         const userSelectedAudit: any =
            auditData.find(
               (audit: any) => audit?.AuditID === selectedAuditId
            ) ?? defaultAudit
         setSelectedAudit(userSelectedAudit)
         if (userSelectedAudit?.Status) {
            getAuditRecord(
               {
                  auditID: Number(userSelectedAudit?.AuditID),
                  skip: 0,
                  take: 10,
               },
               userSelectedAudit?.Status
            )
         }
      }
   }, [selectedAuditId])

   useEffect(() => {
      if (auditRecord?.AuditRecordDetails) {
         setSelectedAuditFormData(auditRecord.AuditRecordDetails)
         setInitialAuditFormData(auditRecord.AuditRecordDetails)
      }
   }, [auditRecord])

   // if draft status or no status
   useEffect(() => {
      if (selectedAuditFormData.Status === AuditStatus.DRAFT) {
         setFinalisedSelectedItems(
            draftAuditItemsListAndCount?.DraftItems ?? []
         )
      }
   }, [draftAuditItemsListAndCount, selectedAuditFormData?.Status])

   // if audit has status and non draft
   useEffect(() => {
      if (
         selectedAuditFormData.Status &&
         selectedAuditFormData.Status !== AuditStatus.DRAFT
      ) {
         setSelectedAuditFormItemsUnconfirmed(auditUnconfirmedItemsList as any)
         setSelectedAuditFormItemsConfirmed(auditConfirmedItemsList)
         setSelectedAuditFormItemsFound(auditFoundItemsList)
      }
   }, [
      auditConfirmedItemsList,
      auditUnconfirmedItemsList,
      auditFoundItemsList,
      selectedAuditFormData?.Status,
   ])

   useEffect(() => {
      // use selected audit here to get audit record
      // validate on opening audit as well as validating on dropdown change
      validateAuditorAvailability(selectedAudit.AuditedByID)
   }, [selectedAudit])

   useEffect(() => {
      setPath(crudPaths, key)
   }, [])

   // handle actions
   useEffect(() => {
      handleSnackbar('Audit has been updated.', Severity.SUCCESS)
   }, [isSuccessUpdate])

   useEffect(() => {
      handleSnackbar('Audit has been created.', Severity.SUCCESS)
   }, [isSuccessCreate])

   useEffect(() => {
      handleSnackbar('Audit has been removed.', Severity.SUCCESS)
   }, [isSuccessRemove])

   // handle errors
   useEffect(() => {
      handleSnackbar('Creating a audit has been unsuccessful.', Severity.ERROR)
   }, [isErrorCreate])

   useEffect(() => {
      handleSnackbar('Updating a audit has been unsuccessful.', Severity.ERROR)
   }, [isErrorUpdate])

   useEffect(() => {
      handleSnackbar('Deleting a audit has been unsuccessful.', Severity.ERROR)
   }, [isErrorRemove])

   useEffect(() => {
      if (createIsSuccess) {
         handleSnackbar('Audit has been added successfully', Severity.SUCCESS)
         resetAndCloseModal()
      }
   }, [createIsSuccess])

   const handleSubmit = (requestType: AuditRequestType) => {
      const itemsToAdd = !selectedAuditFormData.AuditID
         ? finalisedSelectedItems
         : finalisedSelectedItems.filter(
              (elem) =>
                 !draftAuditItemsListAndCount?.DraftItems?.find(
                    ({ localUniqueId }) => elem.localUniqueId === localUniqueId
                 )
           )
      const preparedAudit = prepareAuditForAPI(
         {
            ...selectedAuditFormData,
            AuditItems: itemsToAdd,
         },
         requestType,
         existingDraftAuditItemsForDeletion
      )

      createAudit(preparedAudit)
      validateAuditorAvailability(null)
      handleClose()
   }

   const resetAndCloseModal = () => {
      setSelectedAudit(defaultAudit)
      setIsAddEditModalVisible(false)
      setIsDeleteModalVisible(false)
      setIsExportModalVisible(false)
      setIsCancelWarningVisible(false)
      setSelectedItemsForAudit([])
      // get updated audit data on modal close
      refresh()
   }

   const calculateAuditItemsTotal = () => {
      if (
         !selectedAuditFormData.AuditID ||
         (selectedAuditFormData.AuditID &&
            selectedAuditFormData.Status === AuditStatus.DRAFT)
      ) {
         return finalisedSelectedItems?.length ?? 0
      } else if (
         selectedAuditFormData.AuditID &&
         selectedAuditFormData.Status !== AuditStatus.DRAFT
      ) {
         return selectedAuditFormData.ItemTotal
      } else {
         return 0
      }
   }

   const handleSelectChange = (event: ChangeEvent<HTMLFormElement>) => {
      const { name, value } = event.target
      if (addAuditView === AddAuditView.ItemSelector) {
         modifyItemFilters(name, '', value)
      } else {
         modify(name, '', value)
      }
   }

   const applySavedFilters = (filters: FiltersObject) => {
      setFiltersOpen(false)
      if (addAuditView === AddAuditView.ItemSelector) {
         setItemFilterValues(formatFiltersForAPI(filters))
      } else {
         handleGetItems(searchCriteria, 0, pageSized, filters)
         setFilterValues(formatFiltersForAPI(filters))
      }
   }

   const handleGetItems = (
      query?: string,
      skip?: number,
      take?: number,
      filters?: {}
   ) => {
      const filterSet = savedFilters
      const formattedFilters = formatFiltersForAPI(filters || filterSet)
      setFilterValues(formattedFilters)
      get(query, skip, take, formattedFilters)
   }

   const handleAuditSearch = (
      query?: string,
      skip?: number,
      take?: number,
      filters?: {}
   ) => {
      const formattedFilters = formatFiltersForAPI(filters || savedFilters)
      setFilterValues({ ...formattedFilters, query })

      get(query, skip, take, formattedFilters)
   }

   const handleItemSearch = (
      query?: string,
      skip?: number,
      take?: number,
      filters?: {}
   ) => {
      const formattedFilters = formatFiltersForAPI(filters || savedItemFilters)
      setItemFilterValues({ ...formattedFilters, query })
   }

   const handleAddEditModalVisible = () => {
      setIsAddEditModalVisible(true)
      setFiltersOpen(false)
   }

   const handleCancelCloseAudit = () => {
      setIsCancelWarningVisible(false)
      handleAddEditModalVisible()
   }

   const hasUnsavedChangesOnClose = () => {
      if (selectedAuditFormData.Status !== AuditStatus.DRAFT) return false // should be no changes against these.
      if (itemsWereAddedOrRemoved) return true
      if (
         new Date(selectedAuditFormData.DateDue).toString() !==
         new Date(initialSelectedAuditFormData.DateDue).toString()
      )
         return true
      if (
         new Date(selectedAuditFormData.DateSent).toString() !==
         new Date(initialSelectedAuditFormData.DateSent).toString()
      )
         return true
      if (
         new Date(selectedAuditFormData.DateReceived).toString() !==
         new Date(initialSelectedAuditFormData.DateReviewed).toString()
      )
         return true
      if (
         selectedAuditFormData.AuditedByID !==
         initialSelectedAuditFormData.AuditedByID
      )
         return true
      if (
         new Date(selectedAuditFormData.DateReviewed).toString() !==
         new Date(initialSelectedAuditFormData.DateReviewed).toString()
      )
         return true
      return false
   }

   const handleCheckCancelAudit = () => {
      setIsAddEditModalVisible(false)
      if (hasUnsavedChangesOnClose()) {
         setIsCancelWarningVisible(true)
      } else if (
         selectedAuditFormData.Status === AuditStatus.DRAFT &&
         selectedAuditFormData.AuditID
      ) {
         handleSaveAudit()
      } else {
         handleClose()
      }
      // reset audit dropdown
      validateAuditorAvailability(null)
   }

   const handleClose = () => {
      resetAndCloseModal()
      setAddAuditView(AddAuditView.Default)
      setIsAllItemsSelected(false)
      setFiltersOpen(false)
      setSelectedAudit(defaultAudit)
      setSelectedAuditId(null)
      setAuditIdForDeletion(null)
      setSelectedItemsForAudit([])
      setFinalisedSelectedItems([])
      setSelectedAuditFormItemsConfirmed([])
      setSelectedAuditFormItemsUnconfirmed([])
      setSelectedAuditFormItemsFound([])
      setExistingDraftAuditItemsForDeletion([])
      setSelectedAuditFormData(defaultAuditRecordDetails)
      setInitialAuditFormData(defaultAuditRecordDetails)
      getAuditRecord(null, null)
      setItemsWereAddedOrRemoved(false)
      refresh() // refresh 1
   }

   const handleSaveAudit = () => {
      handleSubmit('save')
      handleResetAllPageFilters()
   }

   const handleSaveAndSendAudit = () => {
      handleSubmit('send')
      handleResetAllPageFilters()
   }

   const handleAuditReviewed = () => {
      markSelectedAuditAsReviewed(selectedAuditFormData.AuditID)
      handleClose()
      refresh()
   }

   const handleOnDeleteAuditClick = () => {
      remove(auditIdForDeletion)
      setIsDeleteModalVisible(false)
      setAuditIdForDeletion(null)
   }

   const handleDeleteAuditCancelClick = () => {
      setIsDeleteModalVisible(false)
      setAuditIdForDeletion(null)
   }

   const showActionForm = (action, id?: string) => {
      switch (action) {
         case Actions.Add:
            setSelectedAuditId(null)
            setSelectedAuditFormData(defaultAuditRecordDetails)
            handleAddEditModalVisible()
         case Actions.Edit:
            setSelectedAuditId(Number(id))
            handleAddEditModalVisible()
            break
         case Actions.Delete:
            setAuditIdForDeletion(Number(id))
            setIsDeleteModalVisible(true)
            break
         case Actions.Export:
            downloadCsv(
               downloadHistoryPath,
               exportItemsFileName,
               Number(id),
               setIsDownloadingFileDirect
            )
            break
      }
   }

   const handleDateChange = (name: string, date: Date) => {
      setSelectedAuditFormData({ ...selectedAuditFormData, [name]: date })
   }

   const handleDropdownChange = (
      name: string,
      label: string,
      options: Option[] | Option
   ) => {
      const filterOptions = Array.isArray(options)
         ? options?.map((option) => ({
              label: option.label,
              value: option.value,
           }))
         : [options]

      addAuditView === AddAuditView.ItemSelector
         ? modifyItemFilters(name, label, filterOptions)
         : modify(name, label, filterOptions)
   }

   const handleRemoveTag = (name: string, id: string): void => {
      const newFilters = removeFilter(name, id)
      applySavedFilters(newFilters)
   }

   const handleFilterSave = async () => {
      const isSaved =
         addAuditView === AddAuditView.ItemSelector
            ? await saveItemFilters()
            : await save()
      const filterSet =
         addAuditView === AddAuditView.ItemSelector
            ? tmpItemFilters
            : tmpFilters
      if (isSaved) {
         applySavedFilters(filterSet)
      }
   }

   const handleResetFilters = () => {
      if (addAuditView === AddAuditView.ItemSelector) {
         itemFilterFormRef.current.reset()
         resetItemFilters()
      } else {
         formRef.current.reset()
         reset()
      }
      applySavedFilters({})
   }

   const handleResetAllPageFilters = () => {
      if (itemFilterFormRef.current) itemFilterFormRef.current.reset()
      if (formRef.current) formRef.current.reset()
      setItemFilterValues({})
      resetItemFilters()
      reset()
   }

   const showDownloadModal = () => setIsExportModalVisible(true)

   const handleAddItemToAudit = (itemToAdd: Item) => {
      const isQuantityItem = itemToAdd?.ToolType === 2
      if (isQuantityItem) {
         if (itemToAdd?.StatusQuantity === 0) return
         setFinalisedSelectedItems((prevItems) => [...prevItems, itemToAdd])
         const itemDeletionIds = existingDraftAuditItemsForDeletion?.filter(
            (id) => id !== itemToAdd?.AuditItemID
         )
         setExistingDraftAuditItemsForDeletion(itemDeletionIds)
      } else {
         setFinalisedSelectedItems((prevItems) => [...prevItems, itemToAdd])
         const itemDeletionIds = existingDraftAuditItemsForDeletion?.filter(
            (id) => id !== itemToAdd?.AuditItemID
         )
         setExistingDraftAuditItemsForDeletion(itemDeletionIds)
      }
   }

   const handleRemoveItemFromAudit = (itemToRemove: Item) => {
      const updatedAuditItems = finalisedSelectedItems.filter(
         (item) => item.localUniqueId !== itemToRemove.localUniqueId
      )
      setFinalisedSelectedItems(updatedAuditItems)
   }

   // checkbox methods for item selector
   const handleSelectAuditItem = (item: ItemType) => {
      const uncheck = selectedItemsForAudit
         .reduce(
            (idArray, nextItem) => [...idArray, nextItem.localUniqueId],
            []
         )
         .includes(item.localUniqueId)

      if (uncheck && isAllItemsSelected) {
         setIsAllItemsSelected(false)
      }

      // is item already a saved item on draft audit and have a auditItemId
      const auditItem = draftAuditItemsListAndCount?.DraftItems?.find(
         (x: AuditItem) => x.localUniqueId === item.localUniqueId
      )

      // add to deletion array when currently saved item unchecked
      if (uncheck && auditItem) {
         setExistingDraftAuditItemsForDeletion((prevState) => [
            ...prevState,
            auditItem?.AuditItemID,
         ])
      }
      // remove item to deletion array if previously added and checked
      else if (
         !uncheck &&
         existingDraftAuditItemsForDeletion.includes(auditItem?.AuditItemID)
      ) {
         const indexToRemove = existingDraftAuditItemsForDeletion.indexOf(
            auditItem?.AuditItemID
         )
         const amendedIdArray = existingDraftAuditItemsForDeletion
         if (indexToRemove > -1) {
            amendedIdArray.splice(indexToRemove, 1)
            setExistingDraftAuditItemsForDeletion(amendedIdArray)
         }
      }

      setSelectedItemsForAudit((selectedItems) =>
         uncheck
            ? selectedItems.filter(
                 (x) => x.localUniqueId !== item.localUniqueId
              )
            : sortObjectsByAlpha([...selectedItems, item], 'Title')
      )
   }

   const handleItemSelectorCancelItems = () => {
      handleResetFilters()
      setSelectedItemsForAudit(finalisedSelectedItems)
      setAddAuditView(AddAuditView.Default)
   }

   const handleSelectAllItemCheckboxes = (itemArray: ItemType[]) => {
      if (!isAllItemsSelected) {
         const currentlySelectedIds = selectedItemsForAudit.reduce(
            (idArray, nextItem) => [...idArray, nextItem.localUniqueId],
            []
         )
         //remove any that were individually selected
         const filteredItemArray = itemArray.filter(
            (item) => !currentlySelectedIds.includes(item.localUniqueId)
         )
         setSelectedItemsForAudit(
            sortObjectsByAlpha(
               [...selectedItemsForAudit, ...filteredItemArray],
               'Title'
            )
         )
         setIsAllItemsSelected(true)
         setIsSelectAllItemsWarningVisible(true)
      } else {
         const itemIdsForPage = itemArray.reduce(
            (idArray, nextItem) => [...idArray, nextItem.localUniqueId],
            []
         )
         // if being unchecking all find any items already saved to opened draft and transfer their AuditItemID to array for deletion
         const auditItemsToRemove =
            draftAuditItemsListAndCount?.DraftItems?.filter((item) =>
               itemIdsForPage.includes(item.localUniqueId)
            ).reduce(
               (idArray, nextItem) => [...idArray, nextItem.AuditItemID],
               []
            ) ?? []
         setExistingDraftAuditItemsForDeletion((prevState) => [
            ...prevState,
            ...auditItemsToRemove,
         ])
         setSelectedItemsForAudit(
            [...selectedItemsForAudit].filter(
               (x) => !itemIdsForPage.includes(x.localUniqueId)
            )
         )
         setIsAllItemsSelected(false)
      }
   }

   // check if all items checkboxes selected
   const getTotalItemsPerPage = (totalItemsForCurrentPage: ItemType[]) => {
      // create id arrays for selected objects
      const totalIdsForPage = totalItemsForCurrentPage.reduce(
         (idArray, nextItem) => [...idArray, nextItem.localUniqueId],
         []
      )
      const currentlySelectedIds = selectedItemsForAudit.reduce(
         (idArray, nextItem) => [...idArray, nextItem.localUniqueId],
         []
      )
      const allOnPageSelected = totalIdsForPage.every((id) =>
         currentlySelectedIds.includes(id)
      )
      setIsAllItemsSelected(allOnPageSelected)
   }

   const handleAddBackToDefaultView = () => {
      setItemsWereAddedOrRemoved(true)
      // finalisedItems becomes finalised plus any extra added or less removed
      setFinalisedSelectedItems(selectedItemsForAudit)
      setAddAuditView(AddAuditView.Default)
   }

   const handleAddItemClick = () => {
      // selected becomes what has already been finalised
      setSelectedItemsForAudit(finalisedSelectedItems)
      setAddAuditView(AddAuditView.ItemSelector)
      setSearchCriteria('')
   }

   const handleAuditedByDropdown = (option) => {
      validateAuditorAvailability(option?.value)
      setSelectedAuditFormData({
         ...selectedAuditFormData,
         AuditedByID: option?.value,
      })
   }

   const getColumns = () => {
      const actionColumn = columnHeaders.find(
         (header) => header.accessor === 'AuditID'
      )
      actionColumn.Cell = (cell) => (
         <CrudActionButtons
            cell={cell}
            action={showActionForm}
            editAction
            deleteAction
            exportAction={cell.row.original.Status !== 1}
         />
      )

      return columnHeaders
   }

   const columns = getColumns()

   const handleSnackbar = (message: string, severity: Severity) => {
      setSnackbarState({ ...snackbarState, message, severity })
   }

   const showSnackbar =
      isSuccessUpdate ||
      isSuccessCreate ||
      isSuccessRemove ||
      isErrorCreate ||
      isErrorUpdate ||
      isErrorRemove
   const showLoadingSpinner =
      isLoading ||
      isUpdating ||
      isCreating ||
      isRemoving ||
      isRefetching ||
      isDownloadingFileDirect ||
      isUpdatingAuditDetails ||
      isUpdatingDraftDetails ||
      isPaginatingItems

   const itemTypeOptions = convertItemTypesToOptions(itemTypes)
   const itemStatusOptions = convertItemStatusStatesToOptions(radioToolStatus)
   const auditStatusOptions = convertItemStatusesToOptions(auditStatuses)
   const connectionsOptions = convertConnectionsToOptions(connections)
   const auditorsOptions = convertConnectionsToOptions(auditors)
   const manufacturerOptions = convertManufacturersToOptions(manufacturers)
   const categoryOptions = convertCategoriesToOptions(categories)
   const locationOptions = convertLocationToOptions(locations)
   const conditionOptions = convertConditionsToOptions(conditions)

   const isSaveAuditEnabled =
      (!!selectedAuditFormData.AuditedByID &&
         auditorAvailabilityStatus?.available) ||
      !!selectedAuditFormData.DateDue ||
      !!finalisedSelectedItems?.length ||
      !!draftAuditItemsListAndCount?.DraftItems?.length

   const isSaveAndSendAuditEnabled =
      !!selectedAuditFormData.AuditedByID &&
      auditorAvailabilityStatus?.available &&
      !!selectedAuditFormData.DateDue &&
      (!!finalisedSelectedItems?.length ||
         !!selectedAuditFormItemsUnconfirmed?.length)

   const cancelButtonLabel = useMemo(
      () =>
         selectedAuditFormData?.Status === AuditStatus.DRAFT ||
         !selectedAuditFormData?.Status
            ? 'Cancel'
            : 'Close',
      [selectedAuditFormData.Status]
   )

   return (
      <div>
         {showLoadingSpinner && <LoadingOverlay />}
         <SnackBar
            message={snackbarState.message}
            open={showSnackbar}
            severity={snackbarState.severity}
            disableAutoClose={snackbarState.disableAutoClose}
         />

         <PageHeader title={tableTitle}>
            <Button
               onClick={() => showActionForm(Actions.Add, '')}
               preserveText
               variant="plain"
               minWidth="0"
            >
               <Svg id="plus" /> {addButtonText}
            </Button>
            <Button
               onClick={showDownloadModal}
               preserveText
               variant="plain"
               disabled={!auditData.length}
               minWidth="0"
            >
               <Svg id="download" width={8} height={8} /> {downloadCsvText}
            </Button>
         </PageHeader>

         <div className={auditsStyles.searchWrapper}>
            <div className={auditsStyles.searchWrapperSearch}>
               <Search
                  handleQuery={handleAuditSearch}
                  searchMode={searchMode}
                  setSearchMode={setSearchMode}
                  pageSized={pageSized}
                  placeHolder={placeholder}
                  setSearchCriteria={setSearchCriteria}
               />
            </div>
            <div className={auditsStyles.searchWrapperFilter}>
               <FilterButton
                  isActive={!isEmptyObject(savedFilters)}
                  onClick={() => setFiltersOpen(!filtersOpen)}
               />
            </div>
         </div>

         <FilterTags filters={savedFilters} onClick={handleRemoveTag} />
         {!isLoading && (
            <TableWrapper
               isLoading={isLoading}
               data={auditData}
               columns={columns}
               totalCount={totalItems}
               getItems={handleGetItems}
               takeItems={PAGINATION.take}
               skipItems={PAGINATION.skip}
               setSearchMode={setSearchMode}
               searchMode={searchMode}
               isPageReset={isPageReset}
               setPageSized={setPageSized}
               extraClassName={extraClassName}
               searchCriteria={searchCriteria}
            />
         )}
         <SlidePanel isOpen={filtersOpen} onClose={() => setFiltersOpen(false)}>
            {addAuditView !== AddAuditView.ItemSelector && (
               <Filters onReset={handleResetFilters} onSave={handleFilterSave}>
                  <form ref={formRef} id="audit-filters-form">
                     <DateRangeFilter
                        dateProps={{
                           from: {
                              defaultValue: tmpFilters?.sentStartDate
                                 ?.value as Date,
                              error: errors?.sentStartDate,
                              id: 'sentStartDate',
                              placeholder: 'Start date',
                           },
                           to: {
                              defaultValue: tmpFilters?.sentEndDate
                                 ?.value as Date,
                              error: errors?.sentEndDate,
                              id: 'sentEndDate',
                              placeholder: 'End date',
                           },
                        }}
                        id="sentStartDate"
                        onChange={modify}
                        title="Date range"
                     />

                     <h3 className={auditsStyles.filterHeading}>
                        Tool Records
                     </h3>

                     <DropdownFilter
                        defaultValue={
                           tmpFilters?.selectedCategories?.value as Option[]
                        }
                        isMulti
                        id="selectedCategories"
                        onChange={handleDropdownChange}
                        options={categoryOptions}
                        title="Category"
                     />

                     <DropdownFilter
                        defaultValue={
                           tmpFilters?.selectedManufacturers?.value as Option[]
                        }
                        isMulti
                        id="selectedManufacturers"
                        onChange={handleDropdownChange}
                        options={manufacturerOptions}
                        title="Manufacturers"
                     />

                     <h3 className={auditsStyles.filterHeading}>
                        Audit Records
                     </h3>

                     <DropdownFilter
                        defaultValue={
                           tmpFilters?.selectedStatus?.value as Option[]
                        }
                        isMulti
                        id="selectedStatus"
                        onChange={handleDropdownChange}
                        options={auditStatusOptions}
                        title="Status"
                     />

                     <DropdownFilter
                        defaultValue={
                           tmpFilters?.selectedConnections?.value as Option[]
                        }
                        isMulti
                        id="selectedConnections"
                        onChange={handleDropdownChange}
                        options={connectionsOptions}
                        title="Connection"
                     />

                     {!!settings?.EnableLocationTracking && (
                        <DropdownFilter
                           defaultValue={
                              tmpFilters?.selectedLocations?.value as Option[]
                           }
                           isMulti
                           id="selectedLocations"
                           onChange={handleDropdownChange}
                           options={locationOptions}
                           title="Location"
                        />
                     )}

                     <DropdownFilter
                        defaultValue={
                           tmpFilters?.selectedConditions?.value as Option[]
                        }
                        isMulti
                        id="selectedConditions"
                        onChange={handleDropdownChange}
                        options={conditionOptions}
                        title="Condition"
                     />

                     <DropdownFilter
                        defaultValue={
                           tmpFilters?.selectedConfrmationType
                              ?.value as Option[]
                        }
                        isMulti
                        id="selectedConfrmationType"
                        onChange={handleDropdownChange}
                        options={[
                           { value: '2', label: 'Manual' },
                           { value: '1', label: 'Scan' },
                        ]}
                        title="Confirmation type"
                     />

                     <DropdownFilter
                        defaultValue={
                           tmpFilters?.selectedItemConfirmation
                              ?.value as Option[]
                        }
                        isMulti
                        id="selectedItemConfirmation"
                        onChange={handleDropdownChange}
                        options={[
                           { value: '1', label: 'Filter By Confirmation' },
                           { value: '2', label: 'Unconfirmed' },
                           { value: '3', label: 'Confirmed' },
                        ]}
                        title="Item confirmation"
                     />
                  </form>
               </Filters>
            )}

            {addAuditView === AddAuditView.ItemSelector && (
               <Filters onReset={handleResetFilters} onSave={handleFilterSave}>
                  <form ref={itemFilterFormRef} id="item-filter-form">
                     <DropdownFilter
                        defaultValue={
                           tmpItemFilters?.itemTypes?.value as Option[]
                        }
                        isMulti
                        id="itemTypes"
                        onChange={handleDropdownChange}
                        options={itemTypeOptions}
                        title="Type"
                     />

                     <Accordion title="Status">
                        <Select
                           error={errors?.toolStatus}
                           options={itemStatusOptions.map((status) => ({
                              key: status.value,
                              value: status.label,
                           }))}
                           id="toolStatus"
                           onChange={handleSelectChange}
                           value={tmpItemFilters?.toolStatus?.value as string}
                           isControlled
                           hideDefaultOption
                           noMargin
                        />
                     </Accordion>

                     <DropdownFilter
                        defaultValue={
                           tmpItemFilters?.selectedConnections
                              ?.value as Option[]
                        }
                        isMulti
                        id="selectedConnections"
                        onChange={handleDropdownChange}
                        options={connectionsOptions}
                        title="Connections"
                     />

                     {!!settings?.EnableLocationTracking && (
                        <DropdownFilter
                           defaultValue={
                              tmpItemFilters?.selectedLocations
                                 ?.value as Option[]
                           }
                           isMulti
                           id="selectedLocations"
                           onChange={handleDropdownChange}
                           options={locationOptions}
                           title="Location"
                        />
                     )}

                     <DropdownFilter
                        defaultValue={
                           tmpItemFilters?.selectedManufacturers
                              ?.value as Option[]
                        }
                        isMulti
                        id="selectedManufacturers"
                        onChange={handleDropdownChange}
                        options={manufacturerOptions}
                        title="Manufacturers"
                     />

                     <DropdownFilter
                        defaultValue={
                           tmpItemFilters?.selectedCategories?.value as Option[]
                        }
                        isMulti
                        id="selectedCategories"
                        onChange={handleDropdownChange}
                        options={categoryOptions}
                        title="Category"
                     />
                  </form>
               </Filters>
            )}
         </SlidePanel>

         <Modal
            isModalVisible={isExportModalVisible}
            closeModal={() => setIsExportModalVisible(false)}
            title={modalTitle}
         >
            <CsvDownloadForm
               primaryActionText="Export"
               secondaryActionText="Cancel"
               criteria={''}
               data={auditData}
               fileName={exportFileName}
               closeModal={handleClose}
               path={downloadPath}
               query={searchCriteria}
               fullDownload={downloadRequest}
               filters={filterValues}
               setIsDownloading={setIsDownloading}
               isDownloading={isDownloading}
               defaultParams={downloadRequest}
               buttonRef={buttonRef}
            />
         </Modal>
         {isAddEditModalVisible && !showLoadingSpinner && (
            <Modal
               isModalVisible={isAddEditModalVisible}
               closeModal={handleCheckCancelAudit}
               title={
                  selectedAuditFormData.Status === AuditStatus.DRAFT ||
                  !selectedAuditFormData.AuditID
                     ? 'Create Audit'
                     : 'Audit Details'
               }
               maxWidth="1200px"
            >
               <div className={auditsStyles.form}>
                  {addAuditView === AddAuditView.Default && (
                     <>
                        <div className={auditsStyles.status}>
                           Current status:{' '}
                           <strong>
                              {selectedAuditFormData?.str_Status ?? 'Draft'}
                           </strong>
                        </div>

                        <Row gutterWidth={20}>
                           <Col xs={12} lg={6}>
                              <DateInput
                                 id="DateDue"
                                 label="Date Due"
                                 minDate={new Date()}
                                 onChange={(date) =>
                                    handleDateChange('DateDue', date)
                                 }
                                 required
                                 disabled={
                                    selectedAuditFormData.AuditID &&
                                    selectedAuditFormData.Status !==
                                       AuditStatus.DRAFT
                                 }
                                 selectedDate={
                                    selectedAuditFormData?.DateDue
                                       ? new Date(
                                            Date.parse(
                                               selectedAuditFormData?.DateDue
                                            )
                                         )
                                       : null
                                 }
                                 noMargin
                              />
                           </Col>
                           <Col xs={12} lg={6}>
                              <Dropdown
                                 error={
                                    !selectedAuditFormData.Status ||
                                    selectedAuditFormData.Status ===
                                       AuditStatus.DRAFT
                                       ? auditorAvailabilityStatus?.message
                                       : null
                                 }
                                 name="AuditedByID"
                                 onChange={handleAuditedByDropdown}
                                 defaultValue={auditorsOptions.filter(
                                    (auditorOption) =>
                                       auditorOption.value ===
                                       selectedAuditFormData?.AuditedByID
                                 )}
                                 options={auditorsOptions}
                                 id="AuditedByID"
                                 label="Audited By"
                                 placeholder="Audited By"
                                 isMulti={false}
                                 disabled={
                                    selectedAuditFormData.AuditID &&
                                    selectedAuditFormData.Status !==
                                       AuditStatus.DRAFT
                                 }
                                 noMargin
                              />
                           </Col>
                        </Row>

                        <hr />

                        <Row gutterWidth={20}>
                           <Col xs={12} lg={4}>
                              <DateInput
                                 id="DateSent"
                                 label="Date Sent"
                                 onChange={(date) =>
                                    handleDateChange('DateSent', date)
                                 }
                                 disabled={true}
                                 selectedDate={
                                    !!selectedAuditFormData?.DateSent
                                       ? new Date(
                                            Date.parse(
                                               selectedAuditFormData?.DateSent
                                            )
                                         )
                                       : null
                                 }
                              />
                           </Col>
                           <Col xs={12} lg={4}>
                              <DateInput
                                 id="DateReceived"
                                 label="Date Received"
                                 onChange={(date) =>
                                    handleDateChange('DateReceived', date)
                                 }
                                 disabled={true}
                                 selectedDate={
                                    !!selectedAuditFormData?.DateReceived
                                       ? new Date(
                                            Date.parse(
                                               selectedAuditFormData?.DateReceived
                                            )
                                         )
                                       : null
                                 }
                              />
                           </Col>
                           <Col xs={12} lg={4}>
                              <DateInput
                                 id="DateReviewed"
                                 label="Date Reviewed"
                                 onChange={(date) =>
                                    handleDateChange('DateReviewed', date)
                                 }
                                 disabled={true}
                                 selectedDate={
                                    !!selectedAuditFormData?.DateReviewed
                                       ? new Date(
                                            Date.parse(
                                               selectedAuditFormData?.DateReviewed
                                            )
                                         )
                                       : null
                                 }
                              />
                           </Col>
                        </Row>
                        {(!selectedAuditFormData.AuditID ||
                           selectedAuditFormData.Status ===
                              AuditStatus.DRAFT) && (
                           <Button
                              onClick={handleAddItemClick}
                              variant="secondary"
                           >
                              Add items
                           </Button>
                        )}

                        <hr />

                        <div className={auditsStyles.status}>
                           Audit Items:{' '}
                           <strong>
                              {calculateAuditItemsTotal()} Item
                              {calculateAuditItemsTotal() !== 1 ? 's' : ''}{' '}
                              added to the audit
                           </strong>
                        </div>

                        <ItemAuditedList
                           unconfirmed={
                              selectedAuditFormData.Status &&
                              selectedAuditFormData.Status !== AuditStatus.DRAFT
                                 ? selectedAuditFormItemsUnconfirmed
                                 : finalisedSelectedItems
                           }
                           confirmed={selectedAuditFormItemsConfirmed}
                           found={selectedAuditFormItemsFound}
                           unconfirmedTotalCount={
                              selectedAuditFormData.Status &&
                              selectedAuditFormData.Status !== AuditStatus.DRAFT
                                 ? selectedAuditFormData.ItemsUnConfirmed
                                 : finalisedSelectedItems?.length
                           }
                           confirmedTotalCount={
                              selectedAuditFormData.ItemsConfirmed
                           }
                           foundTotalCount={
                              selectedAuditFormData.ItemsNotFoundInAudit
                           }
                           onRemoveFromAudit={handleRemoveItemFromAudit}
                           existingDraftAuditItemsForDeletion={
                              existingDraftAuditItemsForDeletion
                           }
                           setExistingDraftAuditItemsForDeletion={
                              setExistingDraftAuditItemsForDeletion
                           }
                           selectedAuditFormData={selectedAuditFormData}
                           itemTypeOptions={itemTypeOptions}
                           categoryOptions={categoryOptions}
                           manufacturerOptions={manufacturerOptions}
                           locationOptions={locationOptions}
                           conditionOptions={conditionOptions}
                           auditIsLoading={auditIsLoading || auditIsRefetching}
                           handleAddAuditItem={handleAddItemToAudit}
                           setItemsWereAddedOrRemoved={
                              setItemsWereAddedOrRemoved
                           }
                        />

                        <hr />

                        <Row
                           gutterWidth={20}
                           className={auditsStyles.saveCancelReviewButtonSet}
                        >
                           {(selectedAuditFormData.Status ===
                              AuditStatus.DRAFT ||
                              !selectedAuditFormData.AuditID) && (
                              <>
                                 <Col xs={4}>
                                    <Button
                                       minWidth="100%"
                                       disabled={!isSaveAuditEnabled}
                                       loading={auditIsLoading}
                                       onClick={handleSaveAudit}
                                       type="button"
                                    >
                                       Save
                                    </Button>
                                 </Col>
                                 <Col xs={4}>
                                    <Button
                                       minWidth="100%"
                                       disabled={!isSaveAndSendAuditEnabled}
                                       loading={auditIsLoading}
                                       onClick={handleSaveAndSendAudit}
                                       variant="success"
                                       type="button"
                                    >
                                       Save &amp; Send
                                    </Button>
                                 </Col>
                              </>
                           )}
                           {selectedAuditFormData.Status ===
                              AuditStatus.RECEIVED && (
                              <Col xs={4}>
                                 <Button
                                    minWidth="100%"
                                    loading={auditIsLoading}
                                    onClick={handleAuditReviewed}
                                    variant="success"
                                    type="button"
                                 >
                                    Reviewed
                                 </Button>
                              </Col>
                           )}
                           <Col xs={4}>
                              <Button
                                 minWidth="100%"
                                 variant="tertiary"
                                 onClick={handleCheckCancelAudit}
                                 type="button"
                                 isReset
                              >
                                 {cancelButtonLabel}
                              </Button>
                           </Col>
                        </Row>
                     </>
                  )}

                  {addAuditView === AddAuditView.ItemSelector && (
                     <>
                        <div className={auditsStyles.searchWrapper}>
                           <div className={auditsStyles.searchWrapperSearch}>
                              <Search
                                 handleQuery={handleItemSearch}
                                 searchMode={searchMode}
                                 setSearchMode={setSearchMode}
                                 pageSized={pageSized}
                                 placeHolder={'items'}
                                 setSearchCriteria={setSearchCriteria}
                              />
                           </div>
                           <FilterButton
                              isActive={!isEmptyObject(savedItemFilters)}
                              onClick={() => setFiltersOpen(!filtersOpen)}
                           />
                        </div>
                        <ItemSelector
                           selectedItems={selectedItemsForAudit}
                           isAllItemsSelected={isAllItemsSelected}
                           handleAddBackToDefaultView={
                              handleAddBackToDefaultView
                           }
                           onSelect={handleSelectAuditItem}
                           onSelectAll={handleSelectAllItemCheckboxes}
                           searchCriteria={searchCriteria}
                           itemFilters={itemFilterValues}
                           handleCancel={handleItemSelectorCancelItems}
                           getTotalItemsPerPage={getTotalItemsPerPage}
                        />
                        {isAllItemsSelected && isSelectAllItemsWarningVisible && (
                           <WarningModal
                              isModalOpen={isAllItemsSelected}
                              handleClose={() => setIsAllItemsSelected(false)}
                              title={'Warning'}
                              message={`The “Select Items” check box selects ONLY the records listed on the page.
                                        If you have multiple pages of records be sure to choose “ALL” at the bottom if you want to select all items.`}
                              primaryButtonTitle={'Continue'}
                              primaryButtonMethod={() =>
                                 setIsSelectAllItemsWarningVisible(false)
                              }
                              primaryButtonVariant={'primary'}
                              secondaryButtonTitle={'Cancel'}
                              secondaryButtonMethod={() => {
                                 setIsAllItemsSelected(false)
                                 setIsSelectAllItemsWarningVisible(false)
                                 setSelectedItemsForAudit([])
                              }}
                              secondaryButtonVariant={'tertiary'}
                           />
                        )}
                     </>
                  )}
               </div>
            </Modal>
         )}
         <Modal
            isModalVisible={isCancelWarningVisible}
            closeModal={handleCancelCloseAudit}
            title="Warning"
         >
            <div className={auditsStyles.deleteForm}>
               <p>
                  You have unsaved changes on this page. Click "STAY ON PAGE" if
                  you want to stay on this page. Click "DISCARD CHANGES" if you
                  want to close and not save changes.
               </p>

               <Row gutterWidth={20}>
                  <Col xs={6}>
                     <Button
                        minWidth="100%"
                        variant="tertiary"
                        onClick={handleCancelCloseAudit}
                        type="button"
                        isReset
                     >
                        Stay On Page
                     </Button>
                  </Col>
                  <Col xs={6}>
                     <Button
                        minWidth="100%"
                        variant="danger"
                        onClick={handleClose}
                        type="button"
                     >
                        Discard Changes
                     </Button>
                  </Col>
               </Row>
            </div>
         </Modal>
         <WarningModal
            isModalOpen={isDeleteModalVisible}
            handleClose={handleDeleteAuditCancelClick}
            title={'Delete Audit'}
            message={'Are you sure you want to delete this audit?'}
            primaryButtonTitle={'Delete'}
            primaryButtonMethod={handleOnDeleteAuditClick}
            primaryButtonVariant={'primary'}
            secondaryButtonTitle={'Cancel'}
            secondaryButtonMethod={handleDeleteAuditCancelClick}
            secondaryButtonVariant={'tertiary'}
         />
      </div>
   )
}

export default memo(Audits)
